<template>
  <v-container fluid
    :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3"
        width="97%"
        height="70">
        <v-row>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportFromMenu"
              offset-y
              :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateFrom"
                  label="Od datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom"
                no-title
                scrollable
                @input="reportFromMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportToMenu"
              offset-y
              :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateTo"
                no-title
                scrollable
                @input="reportToMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-select outlined
              dense
              label="Prodajno mjesto"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object></v-select>
          </div>
          <div style="
              max-width: 18% !important;
              padding-top: 15px;
              padding-right: 10px;
              overflow: auto;
            ">
            <v-btn :disabled="locationNotSelected"
              class="mr-3"
              @click="getData()"><v-icon>mdi-magnify</v-icon></v-btn>
            <v-btn class="mr-2"
              @click="changeWidth()"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
            <v-btn class="ml-2"
              @click="generateExcel()"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
          </div>
          <div style="width: 25% !important">
            <v-switch v-model="showUnpaid"
              label="Uključi neplaćene račune"></v-switch>
          </div>
          <div style="padding-left: 15px; padding-top: 15px; text-align: right"
            class="pt-3 pr-0">
            <v-btn :to="to">X</v-btn>
          </div>
        </v-row>
        <v-row class="pt-0 mt-0 pl-2">
          <div class="d-flex justify-center"></div>
        </v-row>
      </v-card>
    </div>

    <div class="d-flex justify-center">
      <div id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        ">
        <!-- HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear -->
        <table id="firma"
          style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>
                {{ company ? company.address + ", " + company.city : "" }}
              </td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
            <tr>
              <td>
                Adresa prodajnog mjesta:
                {{ location ? location.address + ", " + location.city : "" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table justify="center"
            style="width: 100% !important; border: 0 !important"
            cellspacing="0"
            id="title">
            <thead>
              <tr class="pb-0 mb-0"
                style="border: 0 !important">
                <th colspan="6"
                  class="pb-0 mb-0">
                  <p style="font-weight: bold; font-size: 25px">
                    Porezno izvješće
                  </p>
                </th>
              </tr>
              <tr class="pa-0 ma-0">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 12px">
                    za razdoblje od {{ formatDateFrom }} do
                    {{ formatDateTo }}
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <!-- Codes by Quackit.com -->
        </div>
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            id="tableTraffic"
            v-if="showTableData">
            <thead>
              <tr :class="['header', 'naslov']"
                style="text-align: left">
                <th colspan="5">Pregled poreza po por. grupama</th>
              </tr>
              <tr :class="['header', 'naslov']"
                style="text-align: left">
                <th>Iznos</th>
                <th>Naziv poreza</th>
                <th>Osnovica</th>
                <th>Stopa</th>
                <th>Iznos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tax, tidx) in totalByItemPdv"
                :key="tax.base + tidx">
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(tax.total, 1, currency)
                        .replace("€", "")
                      : tax.total
                  }}
                </td>
                <td>{{ showTaxName(tax.rate) }}</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(tax.base, 1, currency)
                        .replace("€", "")
                      : tax.base
                  }}
                </td>
                <td>{{ tax.rate + ",00 %" }}</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(tax.tax, 1, currency)
                        .replace("€", "")
                      : tax.tax
                  }}
                </td>
              </tr>

              <tr :class="['header', 'naslov']"
                style="font-weight: bold; text-align: left">
                <th>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalPdv.base + totalPdv.tax, 1, currency)
                        .replace("€", "")
                      : totalPdv.base + totalPdv.tax
                  }}
                </th>
                <th>Ukupno</th>
                <th>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalPdv.base, 1, currency)
                        .replace("€", "")
                      : totalPdv.base
                  }}
                </th>
                <th>Ukupno porez</th>
                <th>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalPdv.tax, 1, currency)
                        .replace("€", "")
                      : totalPdv.tax
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table justify="center"
            style="width: 100% !important"
            border="1"
            class="pt-5"
            cellspacing="0"
            v-if="showTableData"
            id="tablePayments">
            <thead>
              <tr :class="['header', 'naslov']"
                style="text-align: left">
                <th>Način plaćanja</th>
                <th>Total</th>
                <th>Naziv poreza</th>
                <th>Osnovica</th>
                <th>Stopa</th>
                <th>Iznos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c, cidx) in cash.pdv"
                :key="c.base + cidx">
                <td v-if="cidx === 0"
                  :rowspan="cash.pdv.length + 1"
                  style="font-weight: bold">
                  Gotovina
                </td>
                <td v-if="cidx === 0"
                  :rowspan="cash.pdv.length + 1"
                  style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(cash.total, 1, currency)
                        .replace("€", "")
                      : cash.total
                  }}
                </td>
                <td style="text-align: right">
                  {{ showTaxName(c.rate) }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(c.base, 1, currency)
                        .replace("€", "")
                      : c.base
                  }}
                </td>
                <td style="text-align: right">
                  {{ c.rate + ",00 %" }}
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(c.tax, 1, currency)
                        .replace("€", "")
                      : c.tax
                  }}
                </td>
              </tr>
              <tr v-if="cash && cash.pdv && cash.pdv.length > 0">
                <td colspan="3"
                  style="text-align: right; font-weight: bold">
                  Ukupno
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalCashPdv.tax, 1, currency)
                        .replace("€", "")
                      : totalCashPdv.tax
                  }}
                </td>
              </tr>
              <tr v-for="(c, cidx) in card.pdv"
                :key="c.base + cidx">
                <td v-if="cidx === 0"
                  :rowspan="card.pdv.length + 1"
                  style="font-weight: bold">
                  Kartice
                </td>
                <td v-if="cidx === 0"
                  :rowspan="card.pdv.length + 1"
                  style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(card.total, 1, currency)
                        .replace("€", "")
                      : card.total
                  }}
                </td>
                <td style="text-align: right">
                  {{ showTaxName(c.rate) }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(c.base, 1, currency)
                        .replace("€", "")
                      : c.base
                  }}
                </td>
                <td style="text-align: right">
                  {{ c.rate + ",00 %" }}
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(c.tax, 1, currency)
                        .replace("€", "")
                      : c.tax
                  }}
                </td>
              </tr>
              <tr v-if="card && card.pdv && card.pdv.length > 0">
                <td colspan="3"
                  style="text-align: right; font-weight: bold">
                  Ukupno
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalCardPdv.tax, 1, currency)
                        .replace("€", "")
                      : totalCardPdv.tax
                  }}
                </td>
              </tr>

              <tr v-for="(c, cidx) in other.pdv"
                :key="c.base + cidx">
                <td v-if="cidx === 0"
                  :rowspan="other.pdv.length + 1"
                  style="font-weight: bold; font-weight: bold">
                  Ostalo
                </td>
                <td v-if="cidx === 0"
                  :rowspan="other.pdv.length + 1"
                  style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(other.total, 1, currency)
                        .replace("€", "")
                      : other.total
                  }}
                </td>
                <td style="text-align: right">
                  {{ showTaxName(c.rate) }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(c.base, 1, currency)
                        .replace("€", "")
                      : c.base
                  }}
                </td>
                <td style="text-align: right">
                  {{ c.rate + ",00 %" }}
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(c.tax, 1, currency)
                        .replace("€", "")
                      : c.tax
                  }}
                </td>
              </tr>
              <tr v-if="other && other.pdv && other.pdv.length > 0">
                <td colspan="3"
                  style="text-align: right; font-weight: bold">
                  Ukupno
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalOtherPdv.tax, 1, currency)
                        .replace("€", "")
                      : totalOtherPdv.tax
                  }}
                </td>
              </tr>

              <tr v-for="(c, cidx) in transactional.pdv"
                :key="c.base + cidx">
                <td v-if="cidx === 0"
                  :rowspan="transactional.pdv.length + 1"
                  style="font-weight: bold">
                  Transakcijski račun
                </td>
                <td v-if="cidx === 0"
                  :rowspan="transactional.pdv.length + 1"
                  style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(transactional.total, 1, currency)
                        .replace("€", "")
                      : transactional.total
                  }}
                </td>
                <td style="text-align: right">
                  {{ showTaxName(c.rate) }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(c.base, 1, currency)
                        .replace("€", "")
                      : c.base
                  }}
                </td>
                <td style="text-align: right">
                  {{ c.rate + ",00 %" }}
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(c.tax, 1, currency)
                        .replace("€", "")
                      : c.tax
                  }}
                </td>
              </tr>
              <tr v-if="
                transactional &&
                transactional.pdv &&
                transactional.pdv.length > 0
              ">
                <td colspan="3"
                  style="text-align: right; font-weight: bold">
                  Ukupno
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalTransactionalPdv.tax, 1, currency)
                        .replace("€", "")
                      : totalTransactionalPdv.tax
                  }}
                </td>
              </tr>
              <!-- Zadnji red total -->
              <tr :class="['header', 'naslov']"
                style="font-weight: bold; text-align: left">
                <th style="text-align: right">Ukupno total</th>
                <th style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalPdv.base + totalPdv.tax, 1, currency)
                        .replace("€", "")
                      : totalPdv.base + totalPdv.tax
                  }}
                </th>
                <th colspan="3"
                  style="text-align: right">Ukupno porez</th>
                <th style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(totalPdv.tax, 1, currency)
                        .replace("€", "")
                      : totalPdv.tax
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>

import { DAY, clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'
import avansDiscount from '@/mixins/avans'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: {},
  data: function () {
    return {
      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      locations: [],
      searchLocation: null,
      allReceipts: [],
      reportFromMenu: undefined,
      reportToMenu: undefined,
      company: undefined,
      location: undefined,
      payments: {
        pdv: [],
        pdvTotal: { base: 0, rate: 'Ukupno', total: 0 }
      },
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      showTableData: false,
      multiplier: 0,
      listeners: [],
      totalByItemPdv: [],
      totalByReceiptPdv: [],
      totalPdv: {},
      currency: 'EUR',
      cash: { pdv: [{ rate: 0, total: 0, tax: 0 }], total: 0 },
      card: { pdv: [{ rate: 0, total: 0, tax: 0 }], total: 0 },
      other: { pdv: [{ rate: 0, total: 0, tax: 0 }], total: 0 },
      transactional: { pdv: [{ rate: 0, total: 0, tax: 0 }], total: 0 },
      totalCashPdv: {},
      totalCardPdv: {},
      totalOtherPdv: {},
      totalTransactionalPdv: {},
      showUnpaid: false
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    this.getMultiplier()

    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())

    if (!this.location || !this.location.id) {
      this.location = this.locations.find(item => item.id === 'all')
    }

    if (this.company.type === 'pausalni_obrt') {
      this.showUnpaid = false
    } else {
      this.showUnpaid = true
    }
  },
  methods: {
    showTaxName (rate) {
      var tax = 'PDV '

      return tax + rate + ' %'
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    getPdvDiscounted (receipt, reprezentacija) {
      var tempPdv = []
      if (receipt.pdv_discounted && receipt.pdv_discounted.length > 0) {
        if (reprezentacija === true) {
          var b = 160
          var t = 200
          var tx = 40
          var rate = 2500

          if (receipt.issue_date_time > 1672531210) {
            b = 40
            t = 50
            tx = 10
          }

          if (receipt.type.flag === 'STORNO') {
            b *= -1
            t *= -1
            tx *= -1
          }

          if (state.getCurrentCompany().is_taxed === false) {
            rate = 0
            b = 50
            t = 50
            tx = 0
          }

          tempPdv.push({
            rate: (rate),
            base: (b),
            total: (t),
            tax: (tx),
            count: 1,
            paymentMethod: receipt.payment_method
          })
        } else {
          receipt.pdv_discounted.forEach(pdv => {
            tempPdv.push({
              rate: (pdv.rate),
              base: (pdv.base),
              total: (pdv.total + pdv.base),
              tax: (pdv.total),
              count: 1,
              paymentMethod: receipt.payment_method
            })
          })
        }
      }

      return tempPdv
    },
    getPdvAndRemoveDiscountIfExist (receipt, reprezentacija) {
      var tempPdv = []
      if (receipt.pdv && receipt.pdv.length > 0) {
        if (reprezentacija === true) {
          var b = 160
          var t = 200
          var tx = 40

          if (receipt.issue_date_time > 1672531210) {
            b = 40
            t = 50
            tx = 10
          }

          if (receipt.type.flag === 'STORNO') {
            b *= -1
            t *= -1
            tx *= -1
          }

          tempPdv.push({
            rate: (2500),
            base: (b),
            total: (t),
            tax: (tx),
            count: 1,
            paymentMethod: receipt.payment_method
          })
        } else {
          receipt.pdv.forEach(pdv => {
            tempPdv.push({
              rate: (pdv.rate),
              base: (pdv.base),
              total: (pdv.total + pdv.base),
              tax: (pdv.total),
              count: 1,
              paymentMethod: receipt.payment_method
            })
          })
        }
      }

      if (tempPdv && tempPdv.length > 0) {
        // Ako postoji popust na iznos racuna onda skini na pojedinacnim artiklima
        var d = clone(receipt.discount)
        if (d && d.active === true && d.type !== '' && reprezentacija === false) {
          let totalRemove = d.amount / 100

          tempPdv.sort((a, b) => b.rate - a.rate) // b - a for reverse sort

          if (d.name === 'AVANS-STORNO-RECEIPT') {
            if (receipt.type.flag !== 'STORNO') {
              tempPdv = avansDiscount(tempPdv, receipt.tags)
            }
          } else {
            tempPdv.forEach((p, pkey) => {
              if (p.base > 0 && p.total > 0) {
                let totalPrice = p.total / 100

                if (totalPrice >= (1 + (p.rate / 10000))) {
                  let removedAllPossible = false
                  let remove = totalRemove

                  // kreni unazad, uzmi ukupni iznos za skidanje i probaj skinuti
                  for (var j = totalRemove; j > 0 && removedAllPossible === false; j--) {
                    // ako oduzmes iznos i on je veci od 100 na kraju i ustvari si skinuo sve, tu zavrsi
                    if ((totalPrice - j) >= (1 + (p.rate / 10000))) {
                      removedAllPossible = true
                      remove = j
                    }
                  }

                  if (removedAllPossible === true) {
                    totalPrice -= remove
                    totalRemove -= remove
                  }
                }

                tempPdv[pkey].total = totalPrice * 100
                tempPdv[pkey].base = parseInt(parseFloat(tempPdv[pkey].total) / (1 + parseFloat(tempPdv[pkey].rate) / 10000))
                tempPdv[pkey].tax = (tempPdv[pkey].total) - (tempPdv[pkey].base)
              }
            })
          }
        }
      }

      return tempPdv
    },
    async getData () {
      this.showLoader()
      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      const dateTo = new Date(this.dateTo).getTime() / 1000
      var cash = {
        pdv: [],
        total: 0
      }

      var card = {
        pdv: [],
        total: 0
      }

      var other = {
        pdv: [],
        total: 0
      }

      var transactional = {
        pdv: [],
        total: 0
      }

      this.allReceipts = []

      this.totalCashPdv = { base: 0, tax: 0, total: 0 }
      this.totalCardPdv = { base: 0, tax: 0, total: 0 }
      this.totalOtherPdv = { base: 0, tax: 0, total: 0 }
      this.totalTransactionalPdv = { base: 0, tax: 0, total: 0 }
      this.totalPdv = { base: 0, tax: 0, total: 0 }

      let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .where('issue_date_time', '>=', dateFrom).where('issue_date_time', '<=', dateTo + 86399)

      if (this.location && this.location.id !== 'all') {
        query = query.where('location_details.id', '==', this.location.id)
      }

      await query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              var rcpt = item.data()
              if (this.showUnpaid === false) {
                if (rcpt.payment && (rcpt.payment.current_status === 'PAID' || rcpt.payment.current_status === 'REVERSED')) {
                  this.allReceipts.push(item.data())
                }
              } else {
                this.allReceipts.push(item.data())
              }
            })
          }
        })

      this.allReceipts.sort((a, b) => a.issue_date_time - b.issue_date_time) // b - a for reverse sort

      var totalByReceiptPdv = []
      // var c = 0; var t = 0; var o = 0; var ca = 0
      this.allReceipts.forEach(receipt => {
        // if (receipt.payment_method === 'NOVČANICE') {
        //   c += receipt.total
        // }
        // if (receipt.payment_method === 'KARTICA') {
        //   ca += receipt.total
        // }
        // if (receipt.payment_method === 'TRANSAKCIJSKI RAČUN') {
        //   t += receipt.total
        // }
        // if (receipt.payment_method === 'OSTALO') {
        //   o += receipt.total
        // }

        var reprezentacija = false
        if (receipt.tags) {
          receipt.tags.forEach(tag => {
            if (tag.receipt_type && tag.receipt_type === 'REPREZENTACIJA') {
              reprezentacija = true
            }
          })
        }

        var tempPdv = []
        // OTKOMENITIRAJ ZA PROVJERU KRIVIH RACUNA
        // var totalByPdv = 0
        if (receipt.pdv_discounted && receipt.pdv_discounted.length > 0) {
          tempPdv = this.getPdvDiscounted(receipt, reprezentacija)
        } else if (receipt.pdv && receipt.pdv.length > 0) {
          tempPdv = this.getPdvAndRemoveDiscountIfExist(receipt, reprezentacija)
        }

        if (tempPdv && tempPdv.length > 0) {
          tempPdv.forEach(pdv => {
            // OTKOMENITIRAJ ZA PROVJERU KRIVIH RACUNA
            // totalByPdv += pdv.base + pdv.tax
            const base = Math.round(pdv.base * 100) / 100
            const total = Math.round(pdv.total * 100) / 100
            const tax = Math.round((pdv.tax) * 100) / 100

            const idx = totalByReceiptPdv.findIndex(p => p?.rate === pdv.rate)
            if (idx > -1) {
              totalByReceiptPdv[idx].base += base
              totalByReceiptPdv[idx].total += total
              totalByReceiptPdv[idx].tax += tax
              totalByReceiptPdv[idx].count += 1
            } else {
              totalByReceiptPdv.push({
                rate: (pdv.rate),
                base: base,
                total: (total),
                tax: (tax),
                count: 1
              })
            }

            // Podaci za drugu tablicu po načinima plaćanja
            if (pdv.paymentMethod === 'NOVČANICE') {
              this.totalCashPdv.base += base / 100
              this.totalCashPdv.tax += tax / 100
              this.totalCashPdv.total += total / 100

              const idx = cash.pdv.findIndex(p => p?.rate === pdv.rate / 100)
              if (idx > -1) {
                cash.pdv[idx].base += base / 100
                cash.pdv[idx].tax += tax / 100
                cash.total += total / 100
              } else {
                cash.total += total / 100
                var p = {
                  base: base / 100,
                  rate: pdv.rate / 100,
                  tax: tax / 100
                }
                cash.pdv.push(p)
              }
            }

            if (pdv.paymentMethod === 'KARTICA') {
              this.totalCardPdv.base += base / 100
              this.totalCardPdv.tax += tax / 100
              this.totalCardPdv.total += total / 100

              const idx = card.pdv.findIndex(p => p?.rate === pdv.rate / 100)
              if (idx > -1) {
                card.pdv[idx].base += base / 100
                card.pdv[idx].tax += tax / 100
                card.total += total / 100
              } else {
                card.total += total / 100
                p = {
                  base: base / 100,
                  rate: pdv.rate / 100,
                  tax: tax / 100
                }
                card.pdv.push(p)
              }
            }

            if (pdv.paymentMethod === 'TRANSAKCIJSKI RAČUN') {
              this.totalTransactionalPdv.base += base / 100
              this.totalTransactionalPdv.tax += tax / 100
              this.totalTransactionalPdv.total += total / 100

              const idx = transactional.pdv.findIndex(p => p?.rate === pdv.rate / 100)
              if (idx > -1) {
                transactional.pdv[idx].base += base / 100
                transactional.pdv[idx].tax += tax / 100
                transactional.total += total / 100
              } else {
                transactional.total += total / 100
                p = {
                  base: base / 100,
                  rate: pdv.rate / 100,
                  tax: tax / 100
                }
                transactional.pdv.push(p)
              }
            }

            if (pdv.paymentMethod === 'OSTALO') {
              this.totalOtherPdv.base += base / 100
              this.totalOtherPdv.tax += tax / 100
              this.totalOtherPdv.total += total / 100

              const idx = other.pdv.findIndex(p => p?.rate === pdv.rate / 100)
              if (idx > -1) {
                other.pdv[idx].base += base / 100
                other.pdv[idx].tax += tax / 100
                other.total += total / 100
              } else {
                other.total += total / 100
                p = {
                  base: base / 100,
                  rate: pdv.rate / 100,
                  tax: tax / 100
                }
                other.pdv.push(p)
              }
            }
          })

          // OTKOMENITIRAJ ZA PROVJERU KRIVIH RACUNA
          // if (receipt.total !== totalByPdv) {
          //   console.log('krivo', receipt.total, totalByPdv, receipt.designation.number, receipt, receipt.payment_method)
          // }
        }
      })

      // Sluzi samo za formatiranje i zbrajanje ukupnog stanja
      this.totalByItemPdv = totalByReceiptPdv

      this.totalPdv.base = 0
      this.totalPdv.tax = 0
      this.totalPdv.total = 0
      this.totalByItemPdv.forEach((pdv, pIdx) => {
        this.totalByItemPdv[pIdx].base /= 100
        this.totalByItemPdv[pIdx].rate /= 100
        this.totalByItemPdv[pIdx].tax /= 100
        this.totalByItemPdv[pIdx].total /= 100

        this.totalByItemPdv[pIdx].base = Math.round(this.totalByItemPdv[pIdx].base * 100) / 100
        this.totalByItemPdv[pIdx].rate = Math.round(this.totalByItemPdv[pIdx].rate * 100) / 100
        this.totalByItemPdv[pIdx].tax = Math.round(this.totalByItemPdv[pIdx].tax * 100) / 100
        this.totalByItemPdv[pIdx].total = Math.round(this.totalByItemPdv[pIdx].total * 100) / 100

        this.totalPdv.base += pdv.base
        this.totalPdv.tax += pdv.tax
        this.totalPdv.total += pdv.total
      })

      this.cash = cash
      this.card = card
      this.other = other
      this.transactional = transactional
      this.showTableData = true

      this.hideLoader()

      // // Ukupni pdv iz pojedinacnog artikla
      // var itemsPdv = []
      // if (reprezentacija === false) {
      //   receipt.items.forEach((it, itIdx) => {
      //     if (it.pdv) {
      //       itemsPdv.push({ base: it.pdv.base, rate: it.pdv.rate, total: it.pdv.total, name: it.name, price: it.price, payment_method: receipt.payment_method })
      //     }
      //   })
      // } else {
      //   if (receipt.total > 0) {
      //     itemsPdv.push({ base: 160, rate: receipt.pdv[0].rate, total: 40, name: '', price: 200, payment_method: receipt.payment_method })
      //   } else {
      //     itemsPdv.push({ base: -160, rate: receipt.pdv[0].rate, total: -40, name: '', price: -200, payment_method: receipt.payment_method })
      //   }
      // }

      // // Ako postoji popust na iznos racuna onda skini na pojedinacnim artiklima
      // var d = clone(receipt.discount)
      // if (d.discount && d.discount.active === true && d.discount.type !== '' && reprezentacija === false) {
      //   if (d.type === 'AMOUNT') {
      //     var alreadyDiscounted = 0
      //     itemsPdv.forEach((itPdv, itIdx) => {
      //       var temp = clone(itPdv)
      //       if (itIdx === itemsPdv.length - 1) {
      //         var itDiscount = d.amount - alreadyDiscounted
      //         alreadyDiscounted += itDiscount
      //         var itPrice = itPdv.price - itDiscount
      //         temp.price = itPrice
      //         temp.price = Math.round(temp.price * 100) / 100
      //         temp.base = itPrice / (1 + itPdv.rate / 10000)
      //         temp.base = Math.round(temp.base * 100) / 100
      //         temp.total = itPrice - temp.base
      //         temp.total = Math.round(temp.total * 100) / 100
      //       } else {
      //         // Nenadova formula
      //         itDiscount = (d.amount * ((itPdv.base + itPdv.total) / receipt.full_total))
      //         alreadyDiscounted += itDiscount
      //         itPrice = itPdv.price - itDiscount
      //         temp.price = itPrice
      //         temp.price = Math.round(temp.price * 100) / 100
      //         temp.base = itPrice / (1 + itPdv.rate / 10000)
      //         temp.base = Math.round(temp.base * 100) / 100
      //         temp.total = itPrice - temp.base
      //         temp.total = Math.round(temp.total * 100) / 100
      //       }
      //       itemsPdv[itIdx] = temp
      //     })
      //   } else if (d.type === 'RATE') {
      //     itemsPdv.forEach((itPdv, itIdx) => {
      //       var temp = clone(itPdv)
      //       var itPrice = itPdv.price * (d.rate / 10000)
      //       temp.price = itPrice
      //       temp.price = Math.round(temp.price * 100) / 100
      //       temp.base = itPrice / (1 + itPdv.rate / 10000)
      //       temp.base = Math.round(temp.base * 100) / 100
      //       temp.total = itPrice - temp.base
      //       temp.total = Math.round(temp.total * 100) / 100

      //       itemsPdv[itIdx] = temp
      //     })
      //   }
      // }

      // Ukupni pdv iz pojedinacnog artikla nakon skidanja PDV-a - zbrajanje
      // itemsPdv.forEach((pdv) => {
      //   if (totalByItemPdv && totalByItemPdv.length > 0) {
      //     var fIdx = totalByItemPdv.findIndex(p => p?.rate === pdv.rate)
      //     if (fIdx > -1) {
      //       const base = Math.round(pdv.base * 100) / 100
      //       const total = Math.round((pdv.total + pdv.base) * 100) / 100
      //       const tax = Math.round((pdv.total) * 100) / 100
      //       totalByItemPdv[fIdx].base += base
      //       totalByItemPdv[fIdx].total += total
      //       totalByItemPdv[fIdx].tax += tax
      //       totalByItemPdv[fIdx].count += 1
      //     } else {
      //       const base = Math.round(pdv.base * 100) / 100
      //       const total = Math.round((pdv.total + pdv.base) * 100) / 100
      //       const tax = Math.round((pdv.total) * 100) / 100
      //       totalByItemPdv.push({
      //         rate: (pdv.rate),
      //         base: base,
      //         total: total,
      //         tax: tax,
      //         count: 1
      //       })
      //     }
      //   } else {
      //     const base = Math.round(pdv.base * 100) / 100
      //     const total = Math.round((pdv.total + pdv.base) * 100) / 100
      //     const tax = Math.round((pdv.total) * 100) / 100
      //     totalByItemPdv.push({
      //       rate: (pdv.rate),
      //       base: base,
      //       total: total,
      //       tax: tax,
      //       count: 1
      //     })
      //   }

      //   let base = pdv.base / 100
      //   base = Math.round(pdv.base * 100) / 100
      //   let total = (pdv.total + pdv.base) / 100
      //   total = Math.round((pdv.total + pdv.base) * 100) / 100
      //   let tax = pdv.total / 100

      //   tax = Math.round((pdv.total) * 100) / 100

      //   // Podaci za drugu tablicu po načinima plaćanja
      //   if (pdv.payment_method === 'NOVČANICE') {
      //     this.totalCashPdv.base += base / 100
      //     this.totalCashPdv.tax += tax / 100
      //     this.totalCashPdv.total += total / 100

      //     const idx = cash.pdv.findIndex(p => p?.rate === pdv.rate / 100)
      //     if (idx > -1) {
      //       cash.pdv[idx].base += base / 100
      //       cash.pdv[idx].tax += tax / 100
      //       cash.total += total / 100
      //     } else {
      //       cash.total += total / 100
      //       var p = {
      //         base: base / 100,
      //         rate: pdv.rate / 100,
      //         tax: tax / 100
      //       }
      //       cash.pdv.push(p)
      //     }
      //   }

      //   if (pdv.payment_method === 'KARTICA') {
      //     this.totalCardPdv.base += base / 100
      //     this.totalCardPdv.tax += tax / 100
      //     this.totalCardPdv.total += total / 100

      //     const idx = card.pdv.findIndex(p => p?.rate === pdv.rate / 100)
      //     if (idx > -1) {
      //       card.pdv[idx].base += base / 100
      //       card.pdv[idx].tax += tax / 100
      //       card.total += total / 100
      //     } else {
      //       card.total += total / 100
      //       p = {
      //         base: base / 100,
      //         rate: pdv.rate / 100,
      //         tax: tax / 100
      //       }
      //       card.pdv.push(p)
      //     }
      //   }
      //   if (pdv.payment_method === 'TRANSAKCIJSKI RAČUN') {
      //     this.totalTransactionalPdv.base += base / 100
      //     this.totalTransactionalPdv.tax += tax / 100
      //     this.totalTransactionalPdv.total += total / 100

      //     const idx = transactional.pdv.findIndex(p => p?.rate === pdv.rate / 100)
      //     if (idx > -1) {
      //       transactional.pdv[idx].base += base / 100
      //       transactional.pdv[idx].tax += tax / 100
      //       transactional.total += total / 100
      //     } else {
      //       transactional.total += total / 100
      //       p = {
      //         base: base / 100,
      //         rate: pdv.rate / 100,
      //         tax: tax / 100
      //       }
      //       transactional.pdv.push(p)
      //     }
      //   }
      //   if (pdv.payment_method === 'OSTALO') {
      //     this.totalOtherPdv.base += base / 100
      //     this.totalOtherPdv.tax += tax / 100
      //     this.totalOtherPdv.total += total / 100

      //     const idx = other.pdv.findIndex(p => p?.rate === pdv.rate / 100)
      //     if (idx > -1) {
      //       other.pdv[idx].base += base / 100
      //       other.pdv[idx].tax += tax / 100
      //       other.total += total / 100
      //     } else {
      //       other.total += total / 100
      //       p = {
      //         base: base / 100,
      //         rate: pdv.rate / 100,
      //         tax: tax / 100
      //       }
      //       other.pdv.push(p)
      //     }
      //   }
      // })
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tablePayments', finalY + 10)

      doc = await setPageNumber(doc)

      doc.save('Porezno izvješće.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic']
        var fileName = 'Porezno izvješće.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      // const all = { name: 'Sva', id: 'all' }
      // this.locations.push(all)
      // this.searchLocation = all
    }
  }
}
</script>
<style scoped>
#tableTraffic {
  border: 1px solid #767676;
}

#tableTax {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.v-btn {
  min-width: 40px !important;
  padding: 0px !important;
}
</style>
