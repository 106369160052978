<template>
  <v-container fluid
    :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3"
        width="97%"
        height="70">
        <v-row>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportFromMenu"
              offset-y
              :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateFrom"
                  label="Od datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom"
                no-title
                scrollable
                @input="reportFromMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportToMenu"
              offset-y
              :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateTo"
                no-title
                scrollable
                @input="reportToMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-select outlined
              dense
              label="Prodajno mjesto"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object></v-select>
          </div>
          <div style="
              max-width: 25% !important;
              padding-top: 15px;
              padding-right: 10px;
              overflow: auto;
              dense
            ">
            <v-btn :disabled="locationNotSelected"
              class="mr-3"
              dense
              @click="getData()"><v-icon>mdi-magnify</v-icon></v-btn>
            <v-btn class="mr-2"
              @click="changeWidth()"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
            <v-btn class="ml-2"
              @click="generateExcel()"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
          </div>
          <div style="width: 25% !important">
            <v-switch v-model="showUnpaid"
              label="Uključi neplaćene račune"></v-switch>
          </div>
          <div style="padding-top: 15px; padding-left: 15px; text-align: right"
            class="pt-3 pr-0">
            <v-btn :to="to">X</v-btn>
          </div>
        </v-row>
        <v-row class="pt-0 mt-0 pl-2">
          <div class="d-flex justify-center"></div>
        </v-row>
      </v-card>
    </div>

    <div class="d-flex justify-center">
      <div id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        ">
        <!-- HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear -->
        <table id="firma"
          style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>{{ company ? company.address + " " + company.city : "" }}</td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Codes by Quackit.com -->

        <!--  -->
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table justify="center"
            style="width: 100% !important; border: 0 !important"
            cellspacing="0"
            id="title">
            <thead>
              <tr class="pb-0 mb-0">
                <th colspan="6"
                  class="pb-0 mb-0">
                  <p style="font-weight: bold; font-size: 25px">
                    Rekapitulacija po kupcima
                  </p>
                </th>
              </tr>
              <tr class="pa-0 ma-0">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 12px">
                    za razdoblje od {{ formatDateFrom }} do
                    {{ formatDateTo }}
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <!-- Codes by Quackit.com -->
        </div>
        <div justify="center">
          <table justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            bordercolor="#cacccb"
            id="tableTraffic"
            v-if="showTableData">
            <thead>
              <tr :class="['header', 'naslov']">
                <th>Način plaćanja</th>
                <th>Kupac</th>
                <th>Iznos</th>
              </tr>
            </thead>
            <tbody style="width: 100% !important">
              <tr v-for="(buyer, bidx) in cash.buyers"
                :key="buyer.name + bidx + 'c'">
                <td v-if="bidx === 0"
                  :rowspan="cash.buyers ? cash.buyers.length + 1 : 2"
                  style="vertical-align: top; text-align: left">
                  Gotovina
                </td>
                <td style="text-align: left">
                  {{
                    buyer.name && buyer.name !== ""
                      ? buyer.name
                      : "[nema kupca]"
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(buyer.total, 100, currency)
                        .replace("€", "")
                      : buyer.total / 100
                  }}
                </td>
              </tr>

              <tr v-if="cash && cash.buyers && cash.buyers.length > 0">
                <td style="
                    text-align: left;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  Ukupno
                </td>
                <td style="
                    text-align: right;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(cash.total, 100, currency)
                        .replace("€", "")
                      : cash.total / 100
                  }}
                </td>
              </tr>

              <tr v-for="(buyer, bidx) in card.buyers"
                :key="buyer.name + bidx + 'ca'">
                <td v-if="bidx === 0"
                  :rowspan="card.buyers ? card.buyers.length + 1 : 2"
                  style="vertical-align: top; text-align: left">
                  Kartice
                </td>
                <td style="text-align: left">
                  {{
                    buyer.name && buyer.name !== ""
                      ? buyer.name
                      : "[nema kupca]"
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(buyer.total, 100, currency)
                        .replace("€", "")
                      : buyer.total / 100
                  }}
                </td>
              </tr>

              <tr v-if="card && card.buyers && card.buyers.length > 0">
                <td style="
                    text-align: left;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  Ukupno
                </td>
                <td style="
                    text-align: right;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(card.total, 100, currency)
                        .replace("€", "")
                      : card.total / 100
                  }}
                </td>
              </tr>

              <tr v-for="(buyer, bidx) in other.buyers"
                :key="buyer.name + bidx + 'o'">
                <td v-if="bidx === 0"
                  :rowspan="other.buyers ? other.buyers.length + 1 : 2"
                  style="vertical-align: top; text-align: left">
                  Ostalo
                </td>
                <td style="text-align: left">
                  {{
                    buyer.name && buyer.name !== ""
                      ? buyer.name
                      : "[nema kupca]"
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(buyer.total, 100, currency)
                        .replace("€", "")
                      : buyer.total / 100
                  }}
                </td>
              </tr>

              <tr v-if="other && other.buyers && other.buyers.length > 0">
                <td style="
                    text-align: left;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  Ukupno
                </td>
                <td style="
                    text-align: right;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(other.total, 100, currency)
                        .replace("€", "")
                      : other.total / 100
                  }}
                </td>
              </tr>

              <tr v-for="(buyer, bidx) in transactional.buyers"
                :key="buyer.name + bidx + 't'">
                <td v-if="bidx === 0"
                  :rowspan="transactional.buyers ? transactional.buyers.length + 1 : 2
                    "
                  style="vertical-align: top; text-align: left">
                  Transakcijski račun
                </td>
                <td style="text-align: left">
                  {{
                    buyer.name && buyer.name !== ""
                      ? buyer.name
                      : "[nema kupca]"
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(buyer.total, 100, currency)
                        .replace("€", "")
                      : buyer.total / 100
                  }}
                </td>
              </tr>

              <tr v-if="
                transactional &&
                transactional.buyers &&
                transactional.buyers.length > 0
              ">
                <td style="
                    text-align: left;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  Ukupno
                </td>
                <td style="
                    text-align: right;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(transactional.total, 100, currency)
                        .replace("€", "")
                      : transactional.total / 100
                  }}
                </td>
              </tr>
              <tr style="text-align: center">
                <td style="background-color: #d3d3d3"></td>
                <td style="
                    text-align: left;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  Ukupno
                </td>
                <td style="
                    text-align: right;
                    font-weight: bold;
                    background-color: #d3d3d3;
                  ">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                        .money(
                          transactional.total +
                          other.total +
                          card.total +
                          cash.total,
                          100,
                          currency
                        )
                        .replace("€", "")
                      : transactional.total / 100 +
                      other.total / 100 +
                      card.total / 100 +
                      cash.total / 100
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>

import { DAY, clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: {},
  data: function () {
    return {
      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      location: null,
      locations: [],
      allReceipts: [],
      reportFromMenu: undefined,
      reportToMenu: undefined,
      company: undefined,
      currency: 'EUR',
      payments: {
        cashPayments: 0,
        cardPayments: 0,
        otherPayments: 0,
        transPayments: 0,
        totalPayments: 0,
        pdv: [],
        pdvTotal: { base: 0, rate: 'Ukupno', total: 0 }
      },
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      showTableData: false,
      cash: { buyers: [{ name: '', total: 0 }], total: 0 },
      card: { buyers: [{ name: '', total: 0 }], total: 0 },
      other: { buyers: [{ name: '', total: 0 }], total: 0 },
      transactional: { buyers: [{ name: '', total: 0 }], total: 0 },
      showUnpaid: false
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())

    if (this.company.type === 'pausalni_obrt') {
      this.showUnpaid = false
    } else {
      this.showUnpaid = true
    }
  },
  methods: {
    async getData () {
      this.showLoader()
      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      const dateTo = new Date(this.dateTo).getTime() / 1000

      this.allReceipts = []

      let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .where('issue_date_time', '>=', dateFrom)
        .where('issue_date_time', '<=', dateTo + 86399)
      if (this.location && this.location.id !== 'all') {
        query = query.where('location_details.id', '==', this.location.id)
      }

      await query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              var rcpt = item.data()
              if (this.showUnpaid === false) {
                if (rcpt.payment && (rcpt.payment.current_status === 'PAID' || rcpt.payment.current_status === 'REVERSED')) {
                  this.allReceipts.push(item.data())
                }
              } else {
                this.allReceipts.push(item.data())
              }
            })
          }
        })

      this.allReceipts.sort((a, b) => a.issue_date_time - b.issue_date_time) // b - a for reverse sort

      var cash = {
        buyers: [],
        total: 0
      }

      var card = {
        buyers: [],
        total: 0
      }

      var other = {
        buyers: [],
        total: 0
      }

      var transactional = {
        buyers: [],
        total: 0
      }

      this.allReceipts.forEach(receipt => {
        if (receipt.payment_method === 'NOVČANICE') {
          if (!receipt.type.payer_name) {
            receipt.type.payer_name = ''
          }
          const idx = cash.buyers.findIndex(p => p?.name === receipt.type.payer_name)
          if (idx > -1) {
            cash.total += receipt.total
            cash.buyers[idx].total += receipt.total
          } else {
            cash.total += receipt.total
            var buyer = {
              name: receipt.type.payer_name,
              total: receipt.total
            }
            cash.buyers.push(buyer)
          }
        }

        if (receipt.payment_method === 'KARTICA') {
          if (!receipt.type.payer_name) {
            receipt.type.payer_name = ''
          }
          const idx = card.buyers.findIndex(p => p?.name === receipt.type.payer_name)
          if (idx > -1) {
            card.total += receipt.total
            card.buyers[idx].total += receipt.total
          } else {
            card.total += receipt.total
            buyer = {
              name: receipt.type.payer_name,
              total: receipt.total
            }
            card.buyers.push(buyer)
          }
        }

        if (receipt.payment_method === 'OSTALO') {
          if (!receipt.type.payer_name) {
            receipt.type.payer_name = ''
          }
          const idx = other.buyers.findIndex(p => p?.name === receipt.type.payer_name)
          if (idx > -1) {
            other.total += receipt.total
            other.buyers[idx].total += receipt.total
          } else {
            other.total += receipt.total
            buyer = {
              name: receipt.type.payer_name,
              total: receipt.total
            }
            other.buyers.push(buyer)
          }
        }

        if (receipt.payment_method === 'TRANSAKCIJSKI RAČUN') {
          if (!receipt.type.payer_name) {
            receipt.type.payer_name = ''
          }
          const idx = transactional.buyers.findIndex(p => p?.name === receipt.type.payer_name)
          if (idx > -1) {
            transactional.total += receipt.total
            transactional.buyers[idx].total += receipt.total
          } else {
            transactional.total += receipt.total
            buyer = {
              name: receipt.type.payer_name,
              total: receipt.total
            }
            transactional.buyers.push(buyer)
          }
        }
      })

      this.cash = cash
      this.card = card
      this.other = other
      this.transactional = transactional
      this.showTableData = true
      this.hideLoader()

      // this.calculateData()
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)

      doc = await setPageNumber(doc)

      doc.save('Rekapitulacija po kupcima.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic']
        var fileName = 'Rekapitulacija po kupcima.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      // const all = { name: 'Sva', id: 'all' }
      // this.locations.push(all)
      // this.location = all
    }
  }
}
</script>
<style scoped>
#tableTraffic {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.v-btn {
  min-width: 40px !important;
  padding: 0px !important;
}
</style>
